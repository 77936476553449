import styled from 'styled-components';
import { Section } from 'src/components/GlobalUI';
import {
	WIDTH_BREAKPOINT_MD,
	WIDTH_BREAKPOINT_SM,
} from '@virgilsecurity/virgil-ui/src/lib/constants';
import bg from 'images/we-believe/Bottom-backg.png';
import bg2x from 'images/we-believe/Bottom-backg@2x.png';
import { withRetinaBackground } from '@virgilsecurity/virgil-ui/src/lib/styled';

export const WeBelieveContent = styled(Section)`
	background-color: #1a202a;
	${withRetinaBackground(bg, bg2x)}
	background-repeat: no-repeat;
	background-size: cover;
	padding: 80px 0;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		padding: 40px 0;
	}
`;

export const WeBelieveTitleContainer = styled.div`
	margin: 0 110px;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		margin: 0 20px;
	}
`;

export const WeBelieveTitle = styled.h2`
	font-family: Titillium Web, sans-serif;
	font-size: 32px;
	font-weight: 600;
	letter-spacing: 1.05px;
	line-height: 40px;
	text-align: center;
	white-space: pre-line;

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		font-size: 23px;
		line-height: 29px;
	}
`;
