import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { CarouselContent, CarouselContainer, CarouselImgContainer } from './Carousel.dump';

export interface CarouselProps extends React.HTMLAttributes<HTMLDivElement> {
	alt?: string;
}

const Carousel: React.FC<CarouselProps> = ({ alt, ...props }) => {
	const data = useStaticQuery(graphql`
		query {
			allFile(filter: { absolutePath: { regex: "images/carousel/" } }) {
				edges {
					node {
						childImageSharp {
							fixed(height: 342) {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			}
		}
	`);

	const imgs = data.allFile.edges.map(el => el.node.childImageSharp.fixed);

	return (
		<CarouselContent {...props}>
			<CarouselContainer>
				{[...imgs, ...imgs].map((fixed, i) => (
					<CarouselImgContainer key={i}>
						<Img fixed={fixed} alt={alt} />
					</CarouselImgContainer>
				))}
			</CarouselContainer>
		</CarouselContent>
	);
};

export default Carousel;
