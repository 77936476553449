import React from 'react';
import { globalHistory } from '@reach/router';
import { Helmet } from 'react-helmet';

export interface SeoWrapperProps {
	description: string;
	title: string;
	keywords: string[];
	banner?: string;
	url?: string;
}

const SeoWrapper: React.FC<SeoWrapperProps> = ({
	description,
	title,
	keywords,
	banner,
	url,
	children,
}) => {
	const canonicalUrl = globalHistory.location.origin || url;
	const image = canonicalUrl ? `${canonicalUrl.replace(/\/$/, '')}/${banner}` : null;

	return (
		<React.Fragment>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta name="keywords" content={keywords.join(', ')} />
				<link rel="canonical" href={globalHistory.location.href} />
				{image && <meta name="image" content={image} />}
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				{image && <meta property="og:image" content={image} />}
				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
				{image && <meta name="twitter:image" content={image} />}
			</Helmet>
			{children}
		</React.Fragment>
	);
};

export default SeoWrapper;
