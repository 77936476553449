import { ReactComponent as IOSIcon } from 'images/intro/app-store-apple.svg';
import { ReactComponent as AndroidIcon } from 'images/intro/google-play-badge.svg';
import { ReactComponent as MacOSIcon } from 'images/intro/macOS.svg';
import { ReactComponent as WindowsIcon } from 'images/intro/windows.svg';
import { ReactComponent as LinuxIcon } from 'images/intro/linux.svg';
import { ReactComponent as GDPRIcon } from 'images/intro/GDPR.svg';
import { ReactComponent as HIPAAIcon } from 'images/intro/HIPAA.svg';

const introTexts = {
	title: 'Encrypted communications over voice, video and text',
	description:
		'Virgil Messenger is the most secure way to stay in touch with your communities, friends, and protect your company communications, intellectual property and privacy.',
	appsMobile: {
		title: 'Download Beta for mobile',
		links: [
			{
				title: 'iPhone & iPad',
				link: 'https://apps.apple.com/app/virgil-messenger/id1374223472',
				Icon: IOSIcon,
			},
			{
				title: 'Android',
				link: 'https://play.google.com/store/apps/details?id=com.virgilsecurity.android.virgil',
				Icon: AndroidIcon,
			},
		],
	},
	appsDesktop: {
		title: 'Download Beta for desktop',
		links: [
			{
				title: 'macOS',
				link: 'https://downloads.virgilsecurity.com/virgil-messenger/macos/nightly/virgil-messenger.dmg',
				Icon: MacOSIcon,
			},
			{
				title: 'Windows',
				link: 'https://downloads.virgilsecurity.com/virgil-messenger/windows/nightly/virgil-messenger.exe',
				Icon: WindowsIcon,
			},
			{
				title: 'Linux',
				Icon: LinuxIcon,
				links: [
					{
						title: 'Ubuntu, Debian',
						link: 'https://downloads.virgilsecurity.com/virgil-messenger/linux/nightly/virgil-messenger.deb',
					},
					{
						title: 'Fedora, Centos',
						link: 'https://downloads.virgilsecurity.com/virgil-messenger/linux/nightly/virgil-messenger.rpm',
					},
				],
			},
		],
	},
	compliant: {
		title: 'Compliant with',
		items: [
			{
				title: 'GDPR',
				Icon: GDPRIcon,
			},
			{
				title: 'HIPAA',
				Icon: HIPAAIcon,
			},
		],
	},
};

export default introTexts;
