import iconPath1 from 'images/security/icon_block_1.svg';
import iconPath2 from 'images/security/icon_block_2.svg';
import iconPath3 from 'images/security/icon_block_3.svg';
import iconPath4 from 'images/security/icon_block_4.svg';
import iconPath5 from 'images/security/icon_block_5.svg';
import iconPath6 from 'images/security/icon_block_6.svg';

const securityTexts = {
	title: 'Security features',
	description:
		'The information you create, store and share is end-to-end encrypted with our public key infrastructure\nand accessible only by you, so you never have to worry about your privacy.',
	cards: [
		{
			headline: 'End-to-end data encryption',
			description:
				'Virgil end-to-end message encryption is based on the Double Ratchet Algorithm, which manages the ongoing renewal and maintenance of short-lived session keys and provides post-compromise security.',
			path: iconPath1,
		},
		{
			headline: 'Secure data and files sharing',
			description:
				'To protect data sharing, we provide Virgil key management system (KMS) that allows generating a specific encryption key for certain data and share this key in encrypted way with data owner.',
			path: iconPath2,
		},
		{
			headline: 'Password protection against hacking',
			description:
				"We apply password-hardened encryption to all users' passwords based on the PHE protocol, thus eliminating the need to store password hashes and solving security issues with weak passwords.",
			path: iconPath3,
		},
		{
			headline: 'Post-compromise protection for stored data',
			description:
				"By using the PHE protocol for data at rest, platform provides protection of users' data even after the encryption keys have been compromised.",
			path: iconPath4,
		},
		{
			headline: 'Trusted contacts',
			description:
				"The platform interacts with Virgil PKI to identify, store and verify the identity of those who you're chatting with.",
			path: iconPath5,
		},
		{
			headline: 'Post-quantum cryptography support',
			description:
				'Virgil provides you with the hybrid post-quantum encryption based on Round5 (key encapsulation) and Falcon  (signature) algorithms.',
			path: iconPath6,
		},
	],
};

export default securityTexts;
