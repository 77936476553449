import styled from 'styled-components';
import { Section } from 'src/components/GlobalUI';
import { titillium42, titillium30, roboto18 } from '@virgilsecurity/virgil-ui/src/lib/fonts';
import { animated } from 'react-spring';
import { Button } from 'src/components/Buttons/Buttons.dump';
import { WIDTH_BREAKPOINT_MD } from '@virgilsecurity/virgil-ui/src/lib/constants';

export const CustomizeContent = styled(Section)`
	background: #f05b5b;
	padding: 105px 0 160px;
	position: relative;
	z-index: 1;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		padding: 20px 0 60%;
	}
`;

interface CustomizeGradientProps {
	gradient: string;
	img: string[];
}

export const CustomizeGradient = styled(animated.div)<CustomizeGradientProps>`
	background-image: url(${({ img }) => img[0]}), ${({ gradient }) => gradient};
	background-position: 150% bottom;
	background-repeat: no-repeat;
	background-size: 70% auto, 100% 100%;
	height: 100%;
	left: 0;
	opacity: 1;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -100;

	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		background-image: url(${({ img }) => img[1]}), ${({ gradient }) => gradient};
	}

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		background-position: center bottom;
		background-size: 100%;
	}
`;

export const CustomizeLeft = styled.div`
	width: 40%;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		margin-left: 10px;
		width: 100%;
	}
`;

export const CustomizeHeadline = styled.h2`
	${titillium42}
	margin: 20px 0;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		${titillium30}
	}
`;

export const CustomizeDescription = styled.p`
	${roboto18}
	margin: 20px 0 30px;
`;

export const CustomizeButton = styled(Button)`
	background: #ffffff;
	border-radius: 20px;
	color: #13191c;
	margin-top: 10px;
	padding: 8px 30px;

	&:hover {
		background: #ffcabc;
	}
`;
