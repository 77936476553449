import styled, { keyframes } from 'styled-components';

export const CarouselContent = styled.div`
	display: flex;
	margin-bottom: -50px;
	overflow: hidden;
	width: 100%;
`;

const loopFrame = keyframes`
	from {
		transform: translateX(0%);
	}

	to {
		transform: translateX(-50%);
	}
`;

export const CarouselContainer = styled.div`
	animation: ${loopFrame} 50s linear infinite;
	display: flex;
	height: 342px;
	margin: 0;
	margin-bottom: 30px;
	padding: 0;
	width: fit-content;
`;

export const CarouselImgContainer = styled.div`
	border-radius: 17px;
	box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.68);
	margin: 10px 20px 0;
`;
