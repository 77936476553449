import React from 'react';
import { Wrapper } from 'src/components/GlobalUI';
import weBelieveTexts from 'texts/WeBelieve.en';
import { WeBelieveContent, WeBelieveTitleContainer, WeBelieveTitle } from './WeBelieve.dump';

const WeBelieve: React.FC = () => {
	return (
		<WeBelieveContent>
			<Wrapper>
				<WeBelieveTitleContainer>
					<WeBelieveTitle>{weBelieveTexts.text}</WeBelieveTitle>
				</WeBelieveTitleContainer>
			</Wrapper>
		</WeBelieveContent>
	);
};

export default WeBelieve;
