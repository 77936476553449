import React from 'react';
import { Wrapper } from 'src/components/GlobalUI';
import pricingTexts from 'texts/Pricing.en';
import Title from 'src/components/Title/Title';
import { PricingContent } from './Pricing.dump';
import { PricingTable } from '@virgilsecurity/virgil-ui/src/components/PricingTable';

const Pricing: React.FC = () => {
	return (
		<PricingContent id="pricing">
			<Wrapper>
				<Title>
					<h2>{pricingTexts.title}</h2>
					<p>{pricingTexts.description}</p>
				</Title>
				<PricingTable
					backgroundHeader={'rgb(27, 28, 40)'}
					backgroundBorder={'rgb(27, 28, 40)'}
					{...pricingTexts.table}
				></PricingTable>
			</Wrapper>
		</PricingContent>
	);
};

export default Pricing;
