import styled from 'styled-components';
import { Section } from 'src/components/GlobalUI';
import { titillium20, roboto16 } from '@virgilsecurity/virgil-ui/src/lib/fonts';
import {
	WIDTH_BREAKPOINT_MD,
	WIDTH_BREAKPOINT_XS,
} from '@virgilsecurity/virgil-ui/src/lib/constants';

export const FeatureContent = styled(Section)`
	display: flex;
	flex-direction: column;
`;

export const FeatureContainer = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		justify-content: space-around;
		text-align: center;
	}
`;

export const FeatureItem = styled.div`
	flex: 1 1 20%;
	margin: 16px;

	svg {
		height: 60px;
		width: 60px;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		flex: 1 1 50%;
		max-width: 40%;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_XS}) {
		max-width: 100%;
	}
`;

export const FeatureHeadline = styled.h3`
	${titillium20}
	margin: 6px 0;
`;

export const FeatureDescription = styled.p`
	${roboto16}
	color: #9b9dac;
	margin: 0;
`;
