import styled from 'styled-components';
import { roboto14 } from '@virgilsecurity/virgil-ui/src/lib/fonts';
import { WIDTH_BREAKPOINT_MD } from '@virgilsecurity/virgil-ui/src/lib/constants';

export const FooterContent = styled.footer`
	background: #11121a;
`;

export const FooterContainer = styled.div`
	align-items: start;
	display: flex;
	padding: 22px 0;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		align-items: center;
		flex-direction: column;
		text-align: center;
	}
`;

export const FooterLink = styled.a`
	${roboto14}
	color: #67697c;
	font-weight: 300;
	padding: 5px 10px;
	transition: all 0.2s;

	&:hover {
		color: white;
	}
`;

export const FooterAppsContainer = styled.div`
	display: flex;
	margin-left: 75px;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		margin: 40px 0 0;
		text-align: left;
	}
`;

export const FooterNav = styled.ul`
	display: flex;
	flex-direction: column;
	margin: 0 35px;
`;

export const FooterNavTitle = styled.li`
	color: #67697c;
	${roboto14}
	font-weight: 400;
	list-style: none;
	padding: 5px 10px;
`;

export const FooterNavLink = styled(FooterLink)`
	color: #dadadb;
`;

export const FooterRight = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		align-items: flex-end;
		margin-left: auto;
	}
`;

export const FooterTermsContainer = styled.div`
	display: flex;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 7px;
		order: 2;
		width: 70%;
	}
`;

export const FooterCopyright = styled.span`
	${roboto14}
	color: #67697c;

	a:hover {
		text-decoration: underline;
	}

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		line-height: 0;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		order: 3;
	}
`;

export const FooterSocial = styled.ul`
	display: flex;
	margin: 30px 0 20px;

	a {
		path {
			transition: all 0.2s;
		}

		&:hover {
			path {
				fill: #d32f2f !important;
			}
		}
	}

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		order: 1;
	}
`;
