import React from 'react';
import { Wrapper } from 'src/components/GlobalUI';
import {
	FooterContent,
	FooterContainer,
	FooterLink,
	FooterAppsContainer,
	FooterNav,
	FooterNavTitle,
	FooterNavLink,
	FooterRight,
	FooterTermsContainer,
	FooterCopyright,
	FooterSocial,
} from './Footer.dump';
import { ReactComponent as LogoIcon } from 'icons/logo-VM.svg';
import introTexts from 'texts/Intro.en';
import { footerNavigationTexts, Copyright } from 'content/texts/Navigation.en.tsx';

const Footer: React.FC = () => {
	return (
		<FooterContent>
			<Wrapper>
				<FooterContainer>
					<FooterLink href="#about">
						<LogoIcon />
					</FooterLink>
					<FooterAppsContainer>
						<FooterNav>
							<FooterNavTitle>{introTexts.appsMobile.title}</FooterNavTitle>
							{introTexts.appsMobile.links.map(({ title, link }, i) => (
								<FooterNavLink key={i + link} href={link}>
									{title}
								</FooterNavLink>
							))}
						</FooterNav>
						<FooterNav css="opacity: 0.4; pointer-events: none;">
							<FooterNavTitle>{introTexts.appsDesktop.title}</FooterNavTitle>
							{introTexts.appsDesktop.links.map(({ title, link }, i) => (
								<FooterNavLink key={i + link} href={link}>
									{title}
								</FooterNavLink>
							))}
						</FooterNav>
					</FooterAppsContainer>
					<FooterRight>
						<FooterTermsContainer>
							{footerNavigationTexts.links.map(({ link, title }) => (
								<FooterLink key={title} href={link}>
									{title}
								</FooterLink>
							))}
						</FooterTermsContainer>
						<FooterSocial>
							{footerNavigationTexts.social.map(({ link, Icon }, i) => (
								<FooterLink key={i} href={link}>
									<Icon />
								</FooterLink>
							))}
						</FooterSocial>
						<FooterCopyright>
							<Copyright />
						</FooterCopyright>
					</FooterRight>
				</FooterContainer>
			</Wrapper>
		</FooterContent>
	);
};

export default Footer;
