import React from 'react';
import { Wrapper } from 'src/components/GlobalUI';
import securityTexts from 'texts/Security.en';
import Title from 'src/components/Title/Title';
import {
	SecurityContent,
	SecurityContainer,
	SecurityCard,
	SecurityHeadline,
	SecurityDescription,
} from './Security.dump';

const Security: React.FC = () => {
	return (
		<SecurityContent id="security">
			<Wrapper>
				<Title>
					<h2>{securityTexts.title}</h2>
					<p>{securityTexts.description}</p>
				</Title>
				<SecurityContainer>
					{securityTexts.cards.map(({ headline, description, path }) => (
						<SecurityCard
							key={headline}
							css={`
								background-image: url(${path});
							`}
						>
							<SecurityHeadline>{headline}</SecurityHeadline>
							<SecurityDescription>{description}</SecurityDescription>
						</SecurityCard>
					))}
				</SecurityContainer>
			</Wrapper>
		</SecurityContent>
	);
};

export default Security;
