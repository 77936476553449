import React from 'react';
import {
	IntroWrapper,
	IntroContent,
	IntroLeft,
	IntroHeadline,
	IntroDescription,
	IntroApps,
	IntroAppLabel,
	IntroAppsLink,
	IntroMobileLink,
	IntroCompliant,
	IntroCompliantLabel,
	IntroCompliantItem,
	IntroLinkContainer,
	IntroSecondContainer,
	IntroSecondLink,
} from './Intro.dump';
import { Wrapper } from 'src/components/GlobalUI';
import introTexts from 'texts/Intro.en';

const Intro: React.FC = () => {
	return (
		<IntroWrapper>
			<IntroContent id="about">
				<Wrapper>
					<IntroLeft>
						<IntroHeadline>{introTexts.title}</IntroHeadline>
						<IntroDescription>{introTexts.description}</IntroDescription>
						<IntroApps>
							<IntroAppLabel>{introTexts.appsMobile.title}</IntroAppLabel>
							{introTexts.appsMobile.links.map(({ link, Icon }, i) => (
								<IntroMobileLink key={i} href={link}>
									<Icon />
								</IntroMobileLink>
							))}
						</IntroApps>
						<IntroApps>
							<IntroAppLabel>{introTexts.appsDesktop.title}</IntroAppLabel>
							{introTexts.appsDesktop.links.map(({ title, Icon, link, links }) => {
								if (typeof link == 'string') {
									return (
										<IntroAppsLink key={title} href={link} className={!link ? 'disable' : ''}>
											<Icon />
											{title}
										</IntroAppsLink>
									);
								} else if (links) {
									return (
										<IntroLinkContainer key={title}>
											<Icon />
											<IntroSecondContainer>
												{title}
												<div>
													{links.map(el => (
														<IntroSecondLink key={el.title} href={el.link}>
															{el.title}
														</IntroSecondLink>
													))}
												</div>
											</IntroSecondContainer>
										</IntroLinkContainer>
									);
								}
							})}
						</IntroApps>
						<IntroCompliant>
							<IntroCompliantLabel>{introTexts.compliant.title}</IntroCompliantLabel>
							{introTexts.compliant.items.map(({ title, Icon }) => (
								<IntroCompliantItem key={title}>
									<Icon />
									{title}
								</IntroCompliantItem>
							))}
						</IntroCompliant>
					</IntroLeft>
				</Wrapper>
			</IntroContent>
		</IntroWrapper>
	);
};

export default Intro;
