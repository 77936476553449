import { ReactComponent as StayIcon } from 'images/feature/stay.svg';
import { ReactComponent as MultiIcon } from 'images/feature/multi.svg';
import { ReactComponent as SecurityIcon } from 'images/feature/security.svg';
import { ReactComponent as HandyIcon } from 'images/feature/handy.svg';
import { ReactComponent as MessagingIcon } from 'images/feature/messaging.svg';
import { ReactComponent as VoiceIcon } from 'images/feature/voice.svg';
import { ReactComponent as DedicatedIcon } from 'images/feature/dedicated.svg';
import { ReactComponent as IoTIcon } from 'images/feature/iot.svg';

const featureTexts = {
	title: 'All messaging functions you love in one application',
	description: 'Keep in touch with your community in the most secure way',
	features: [
		{
			headline: 'Stay in touch',
			description: 'Use direct and group chats to cooperate',
			Icon: StayIcon,
		},
		{
			headline: 'Multi-platform',
			description: 'Conversations history is securely synchronized across all devices',
			Icon: MultiIcon,
		},
		{
			headline: 'Security',
			description: 'End-to-end encryption with Post-quantum cryptography support',
			Icon: SecurityIcon,
		},
		{
			headline: 'Handy',
			description: 'Group your conversations into folders for quick access',
			Icon: HandyIcon,
		},
		{
			headline: 'Messaging',
			description: 'Send files, texts, photos, videos and more',
			Icon: MessagingIcon,
		},
		{
			headline: 'Voice & Video Calls',
			description: 'Make real-time calls within web, desktop, and mobile',
			Icon: VoiceIcon,
		},
		{
			headline: 'Dedicated',
			description: 'Use our Cloud Platform or set up the Platform in your infrastructure',
			Icon: DedicatedIcon,
		},
		{
			headline: 'IoT',
			description:
				'Connect and manage your IoT devices, chatbots and servers within one application',
			Icon: IoTIcon,
		},
	],
};

export default featureTexts;
