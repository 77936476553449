import React, { useEffect } from 'react';
import { globalHistory } from '@reach/router';
import { PageProps } from 'gatsby';
import SeoWrapper from 'src/components/Seo';
import seoPage from 'content/seo.yml';
import Layout from 'src/Layout/Layout';
import Intro from 'src/sections/Intro/Intro';
import Feature from 'src/sections/Feature/Feature';
import Security from 'src/sections/Security/Security';
import Pricing from 'src/sections/Pricing/Pricing';
import WeBelieve from 'src/sections/WeBelieve/WeBelieve';
import Footer from 'src/components/Footer/Footer';
import Customize from 'src/sections/Customize/Customize';

const IndexPage: React.FC<PageProps> = () => {
	useEffect(() => {
		// for remove Google Analytics from req params
		const pattern = RegExp(/(\?_ga=.+)/, 'g');
		if (pattern.test(globalHistory.location.search)) {
			globalHistory.navigate(globalHistory.location.href.replace(pattern, ''));
		}
	}, []);

	return (
		<SeoWrapper
			title={seoPage.tab_title}
			description={seoPage.meta_description}
			keywords={seoPage.meta_keywords}
			banner={seoPage.banner}
			url={seoPage.url}
		>
			<Layout background="#121219">
				<Intro />
				<Feature />
				<Security />
				<Customize />
				<Pricing />
				<WeBelieve />
				<Footer />
			</Layout>
		</SeoWrapper>
	);
};

export default IndexPage;
