const customizeTexts = {
	title: 'Release Virgil Messenger under your brand',
	description:
		'Want to customize and release Virgil Messenger under your brand? Contact us to get more details and discuss the required functionality.',
	button: {
		text: 'Contact us',
		link: 'mailto:support@virgilsecurity.com?subject=A question about Virgil Messenger',
	},
};

export default customizeTexts;
