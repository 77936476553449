import React from 'react';
import { Wrapper } from 'src/components/GlobalUI';
import {
	FeatureContent,
	FeatureContainer,
	FeatureItem,
	FeatureHeadline,
	FeatureDescription,
} from './Feature.dump';
import featureTexts from 'texts/Feature.en';
import Title from 'src/components/Title/Title';
import Carousel from 'src/components/Carousel/Carousel';

const Feature: React.FC = () => {
	return (
		<FeatureContent background="#1B1C26" id="features">
			<Wrapper>
				<Title>
					<h2>{featureTexts.title}</h2>
					<p>{featureTexts.description}</p>
				</Title>
				<FeatureContainer>
					{featureTexts.features.map(({ headline, description, Icon }) => (
						<FeatureItem key={headline}>
							<Icon />
							<FeatureHeadline>{headline}</FeatureHeadline>
							<FeatureDescription>{description}</FeatureDescription>
						</FeatureItem>
					))}
				</FeatureContainer>
			</Wrapper>
			<Carousel alt={featureTexts.title} />
		</FeatureContent>
	);
};

export default Feature;
