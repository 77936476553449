import React, { useState, useEffect } from 'react';
import {
	CustomizeContent,
	CustomizeGradient,
	CustomizeLeft,
	CustomizeHeadline,
	CustomizeDescription,
	CustomizeButton,
} from './Customize.dump';
import { Wrapper } from 'src/components/GlobalUI';
import { ReactComponent as Icon } from 'images/get-started/icon.svg';
import customizeTexts from 'content/texts/Customize.en';
import { useTransition } from 'react-spring';

import slide1 from 'images/get-started/customize-phone-1.png';
import slide1x2 from 'images/get-started/customize-phone-1@2x.png';
import slide2 from 'images/get-started/customize-phone-2.png';
import slide2x2 from 'images/get-started/customize-phone-2@2x.png';
import slide3 from 'images/get-started/customize-phone-3.png';
import slide3x2 from 'images/get-started/customize-phone-3@2x.png';

const backgrounds = [
	{ gradient: 'linear-gradient(85deg, #f05b5b, #ff5395, #e34d92)', img: [slide1, slide1x2] },
	{ gradient: 'linear-gradient(85deg, #f05b5b, #8a74ae, #5b94f0)', img: [slide2, slide2x2] },
	{ gradient: 'linear-gradient(85deg, #f05b5b, #f08d5b, #f0c25b)', img: [slide3, slide3x2] },
];

const Customize: React.FC = () => {
	const [currentIndex, setIndex] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			setIndex(previousState => (previousState < backgrounds.length - 1 ? previousState + 1 : 0));
		}, 5000);

		setTimeout(() => clearInterval(timer), 60000 * 5); // disable animation after 5 minutes
		return () => clearInterval(timer);
	}, []);

	const transitions = useTransition(backgrounds[currentIndex], item => item.gradient, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: { duration: 1000 },
	});

	return (
		<CustomizeContent id="customize">
			{transitions.map(({ item, props, key }) => (
				<CustomizeGradient gradient={item.gradient} img={item.img} key={key} style={props} />
			))}
			<Wrapper>
				<CustomizeLeft>
					<Icon />
					<CustomizeHeadline>{customizeTexts.title}</CustomizeHeadline>
					<CustomizeDescription>{customizeTexts.description}</CustomizeDescription>
					<CustomizeButton href={customizeTexts.button.link}>
						{customizeTexts.button.text}
					</CustomizeButton>
				</CustomizeLeft>
			</Wrapper>
		</CustomizeContent>
	);
};

export default Customize;
