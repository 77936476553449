import styled from 'styled-components';
import {
	WIDTH_BREAKPOINT_MD,
	WIDTH_BREAKPOINT_SM,
	WIDTH_BREAKPOINT_XS,
} from '@virgilsecurity/virgil-ui/src/lib/constants';
import { Section } from 'src/components/GlobalUI';
import {
	titillium14,
	titillium16,
	titillium18,
	roboto16,
	roboto18,
	titillium22,
	titillium34,
	titillium48,
} from '@virgilsecurity/virgil-ui/src/lib/fonts';
import backgroundImage from 'images/intro/Background.png';
import backgroundImage2x from 'images/intro/Background@2x.png';
import DevicesImage from 'images/intro/Devices.png';
import DevicesImage2x from 'images/intro/Devices@2x.png';
import { withRetinaBackground } from '@virgilsecurity/virgil-ui/src/lib/styled';

export const IntroWrapper = styled(Section)`
	${withRetinaBackground(backgroundImage, backgroundImage2x)}
	background-repeat: no-repeat;
	background-size: cover;
`;

export const IntroContent = styled.div`
	${withRetinaBackground(DevicesImage, DevicesImage2x)}
	background-position: 100% center;
	background-repeat: no-repeat;
	background-size: 70%;
	padding: 80px 0 120px;
	width: 100%;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		background-position: bottom right;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		background-position: bottom right;
		background-size: 90%;
		padding-bottom: 200px;
	}
`;

export const IntroLeft = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		width: 50%;
	}
`;

export const IntroHeadline = styled.h1`
	${titillium48}

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		${titillium34}
	}

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		margin-bottom: 20px;
	}
`;

export const IntroDescription = styled.div`
	${roboto18}
	font-size: 16px;
	color: #dedfe6;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		${roboto16}
	}
`;

export const IntroApps = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const IntroAppLabel = styled.div`
	${titillium22}
	margin-top: 30px;
	width: 100%;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		${titillium18}
		margin-top: 25px;
	}
`;

export const IntroMobileLink = styled.a`
	${titillium16}
	align-items: center;
	color: #ffffffaa;
	display: flex;
	margin: 20px 15px;
	text-decoration: none;
	transition: all 0.2s;

	&:nth-child(2) {
		margin-left: 0;
	}

	&:hover {
		color: #ffffff;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_XS}) {
		margin-left: 0;
		margin-right: 30px;
	}
`;

export const IntroAppsLink = styled(IntroMobileLink)`
	white-space: pre-wrap;
	text-align: center;
	line-height: 1em;
	vertical-align: middle;

	&.disable {
		opacity: 30%;
		pointer-events: none;
	}
`;

export const IntroLinkContainer = styled.div`
	display: flex;
	width: 100%;
	margin: 0 0 20px;
	${titillium16}
	align-items: center;
	color: #ffffffaa;
	text-decoration: none;
`;

export const IntroSecondContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const IntroSecondLink = styled.a`
	padding: 0 10px;
	border-left: 1px solid rgba(255, 255, 255, 0.7);
	transition: color 0.2s;

	&:hover {
		color: white;
	}
`;

export const IntroCompliant = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
`;

export const IntroCompliantLabel = styled.div`
	${titillium18}
	font-weight: 300;
	width: 100%;
	margin-bottom: 15px;
`;

export const IntroCompliantItem = styled.div`
	${titillium14}
	font-weight: 300;
	display: flex;
	align-items: center;
	svg {
		margin-right: 12px;
	}

	:last-child {
		margin-left: 50px;
	}
`;
