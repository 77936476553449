import styled from 'styled-components';
import { titillium32, titillium26, roboto18 } from '@virgilsecurity/virgil-ui/src/lib/fonts';
import { WIDTH_BREAKPOINT_MD } from '@virgilsecurity/virgil-ui/src/lib/constants';

export const TitleContent = styled.div`
	text-align: center;

	h2 {
		color: #ffffff;
		${titillium32}
		margin-bottom: 0;
		margin-top: 40px;

		@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
			${titillium26}
		}
	}

	p {
		color: #9b9dac;
		${roboto18}
		margin-top: 8px;
		white-space: pre-line;

		@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
			font-size: 18px;
		}
	}
`;
