import styled from 'styled-components';
import { Section } from 'src/components/GlobalUI';
import { titillium20, roboto16 } from '@virgilsecurity/virgil-ui/src/lib/fonts';
import {
	WIDTH_BREAKPOINT_LG,
	WIDTH_BREAKPOINT_MD,
} from '@virgilsecurity/virgil-ui/src/lib/constants';
import securityBgPath from 'images/security/security-bg.svg';

export const SecurityContent = styled(Section)`
	background: #121219 url(${securityBgPath}) center;
	background-repeat: repeat-x;
	background-size: 57%;
	margin-top: 40px;
	padding: 60px 0 45px;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		background: #121219;
	}
`;

export const SecurityContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@media (max-width: ${WIDTH_BREAKPOINT_LG}) {
		justify-content: space-around;
	}
`;

export const SecurityCard = styled.div`
	background: #21232f left bottom no-repeat;
	border-radius: 6px;
	box-shadow: 0 10px 5px #0d0d12a7;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin: 20px 0;
	padding: 20px 40px;
	position: relative;
	width: 365px;
`;

export const SecurityHeadline = styled.h3`
	${titillium20}
	margin-bottom: 10px;

	&::before {
		background: #d32f2f;
		border-radius: 2px;
		content: '';
		display: inline-block;
		height: 27px;
		left: 0;
		position: absolute;
		width: 3px;
	}
`;

export const SecurityDescription = styled.p`
	${roboto16}

	color: #9b9dac;
	letter-spacing: 0.16px;
	line-height: 26px;
	margin-top: 10px;
`;
